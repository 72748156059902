// Components do sistema
import { HttpHeaders } from '@angular/common/http';
// Model
import { UserAuthV1Model } from 'src/app/auth/models/user-auth-v1.model';

//token comunicação api
import { accountAccessConversor } from '../api';

export class SecurityUtil {
public static update = null;

  public static set(
    account: UserAuthV1Model,
    token: string,
  ) {
    localStorage.setItem('token', token);
    localStorage.setItem('account', btoa(JSON.stringify(account)));
  }

  public static setAccount(
    account: UserAuthV1Model
  ) {
    localStorage.setItem('account', btoa(JSON.stringify(account)));
  }

  public static setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public static getAccount(): UserAuthV1Model | null {
    const data = JSON.parse(atob(localStorage.getItem('account') as string));
    const account: UserAuthV1Model = {
      apelido: data.apelido,
      _idAccount: data._idAccount,
      nome: data.nome,
      telefone: data.telefone,
      email: data.email,
      _avatar: data._avatar,
      lock: data.lock,
      rulesUser: data.rulesUser
    };

    if (data) {
      return account;
    } else {
      return null;
    }
  }

  public static getToken(): string | null {
    const data = localStorage.getItem('token');
    if (data) {
      return data;
    } else {
      return null;
    }
  }

  public static hasToken(): boolean {
    if (this.getToken())
      return true;
    else
      return false;
  }

  public composeHeader() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `bearer ${token}`);
    return headers;
  }

  public composeHeaderConversor() {
    const headers = new HttpHeaders().set('accesstoken', accountAccessConversor);
    return headers;
  }

  public static clear() {
    localStorage.removeItem('token');
    localStorage.removeItem('account');
  }
}
