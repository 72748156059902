<footer class="footer">
	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-6">
				{{year}} © ContabX
			</div>
			<div class="col-sm-6">
				<div class="text-sm-end d-none d-sm-block">
					Desenvolvido pela Epigrafo Soluções e Serviços
				</div>
			</div>
		</div>
	</div>
</footer>