// Componments do sistema
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// API
import { AMORIM_API } from 'src/app/core/api';
// Models
import { loginV1Model } from '../models/login-v1.model';
// util
import { SecurityUtil } from 'src/app/core/utils/security.util';
import { ResultV1Model } from 'src/app/core/models/result-v1.model';

@Injectable({
  providedIn: 'root'
})
export class loginV1Service {

  constructor(
    private http: HttpClient,
		private utilService: SecurityUtil,
  ) { }

  /**
   * Serviço de autenticação
   */
  authentication(
    paramAuth: loginV1Model
  ) {
    return this.http.post<ResultV1Model>(
      `${AMORIM_API}/v1/auth`,
      paramAuth
    );
  }

  // serviço de refresh token
  refreshtoken() {
    return this.http.post(
      `${AMORIM_API}/v1/auth/refreshtoken`,
      null,
      { headers: this.utilService.composeHeader() }
    );
  }
}
