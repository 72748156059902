export const environment = {
  production: true,

  /**
   * Dados para comunicação api amorim
   * (ainda não definido em prod)
   */
  api: 'https://ad.contab.cnt.br/api',

  /**
   * Dados para comunicação com a api do conversor
   */
  apiConversor: 'https://apiofx.epigrafo.com.br', // Servidor produção
  accountAccessConversor: 'ZXBnX1ZVVlN0Ums5MEt3d1RxdkpWUVpHZFdvS3RVQ0NwSGNoU3cwZWlyMVI0cXhSMFBCcjBmUTlVZldxQlZGN2t6b1pmZ0IzQVk0RTcyVDJDa2xZQWxYY3pZWGVlSXg2N3RBUFdFRTEjIw==',
};
