import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layouts/layout.component';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'auth', 
    pathMatch: 'full' 
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
    .then(m => m.AuthModule)
  },
  {
    path: 'config',
    component: LayoutComponent,
    loadChildren: () => import('./routes/configuracoes/configuracoes.module')
      .then(m => m.ConfiguracoesModule)
  },
  {
    path: 'rpa',
    component: LayoutComponent,
    loadChildren: () => import('./routes/rpa/rpa.module')
      .then(m => m.RpaModule)
  },
  {
    path: '**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
