export const environment = {
  production: false,

  /**
   * Dados para comunicação com a api amorim
   */
  api: 'https://apiac.epigrafo.com.br',
  account_token: `R3k0Wmk2THExU3c3TG01TWQyR3Q0VXM3UW42U3ExRWI3VWk4R3UxWXoyRnM4UWU0VHA1WnI2V3kyUXU5SWc0TXo0U2UxRnk2WGs3WWIxQmc1VnQ5QXA2Vm0yWWU3THE2UGQzUw==`,

  /**
   * Dados para comunicação com a api do conversor
   */
  apiConversor: 'https://demoapiofx.epigrafo.com.br',
  accountAccessConversor: 'ZXBnX2pJVndVd0tFdzhrOVBKTW9LWHlQdTFIakZtT01ROUpGdkp5R2loTGJLTExrM3VrbWQ2U2hPOWpoR0hjUU9LUGp0eTNqaVRQU1pUdnA3Y1dTSFNVYkJWclBHSFFtQ1AxREVXTXojIw=='
};
